/* canvas {
    position: static;
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
}

@media (max-width: 768px ) {
    canvas {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
} */

body {
    background-color: white;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin: 0;
    padding: 0;
}